import { Spin } from 'antd'
import React from 'react'

const ReviewModal = () => {
    return (
        <div style={{
            height: "100vh",
            width: "100%",
            background: "rgba(255,255,255,0.3)",
            position: "fixed",
            top: "0",
            zIndex: "99",
            display: "grid",
            placeItems: "center",
            backdropFilter: "blur(5px)"
        }}>
            <big style={{ background: "white",padding:"25px",width:"190px" }}>
                This profile is currently under review process.
            </big>
        </div>
    )
}

export default ReviewModal
