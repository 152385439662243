import { FacebookFilled, InstagramFilled, TwitterSquareFilled, UserOutlined } from '@ant-design/icons'
import { Button, QRCode } from 'antd'
import { Header } from 'antd/es/layout/layout'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ShopList from '../components/ShopList'

const Home = () => {
    const navigate = useNavigate()

    return (
        <>
            <div style={{ margin: "0 auto", maxWidth: "500px", padding: "0 5%" }}>

                <Header style={{ background: "transparent", display: "flex", padding: 0, justifyContent: "space-between", alignItems: "center" }}>
                    <img src="image/logontext.png" alt="error" width={60} />
                    {
                        localStorage.getItem("token")
                            ? <Button onClick={() => navigate("/profile")} icon={<UserOutlined />}>Profile</Button>
                            : <Button onClick={() => navigate("/signup")}>Account</Button>
                    }
                </Header>

                <h3>Create your bussiness online</h3>
                <img src="https://t3.ftcdn.net/jpg/01/27/65/58/240_F_127655811_iv0Vf94XxAy7hneGXka6C1kaT8jAe4qC.jpg" alt="error" width={"100%"} />
                <br /><br />

                <ShopList />

                <h3>How our product works</h3>
                <p>
                    You have to scan qr code then you can see more details of restorants.
                    This qr code you can use for menu card on table or your any other rating and website.
                </p>
                <QRCode
                    errorLevel="H"
                    value="https://restra.in/apk/restraqr.apk"
                    icon="image/logo.png"
                /> <br />


                <h3>Follow for more updates.</h3>
                {/* this is social media  account */}
                <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
                    <Button icon={<FacebookFilled />}>FB</Button>
                    <Button icon={<InstagramFilled />}>Insta</Button>
                    <Button icon={<TwitterSquareFilled />}>Twitter</Button>
                </div> <br />
                <p align="center">All right reserved &copy; RestraQr {new Date().getFullYear()}.</p>

            </div>
        </>)
}

export default Home
