import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Outlet from './pages';
import Rasta from './pages/Restra'; 
import Signup from './pages/Signup';
import Profile from './pages/Profile';

function App() {
  return (
    <BrowserRouter>
    <Routes>
        <Route path='/' element={<Outlet />} >
            <Route path='' element={<Home />} />  
            <Route path='signup' element={<Signup />} /> 
            <Route path='profile' element={<Profile />} /> 
            <Route path=':id' element={<Rasta />} />
        </Route>
 
    </Routes>
</BrowserRouter >
  );
}

export default App;
