import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Input, message } from 'antd'
import { Header } from 'antd/es/layout/layout'
import axios from '../axios/index'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Signup = () => {
    const navigate = useNavigate()
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState({
        email: "",
        password: ""
    })
    const handleSignup = () => {
        if (!data.email) return message.warning("Email is required.")
        if (!data.password) return message.warning("Password is required.")
        setloading(true)

        axios.post("/user/add", data)
            .then(e => {
                e?.data && message.success("Success")
                e?.data && localStorage.setItem("token", e?.data?._id)
                e?.data && navigate("/profile", { replace: true })
            })
            .catch(err => console.log(err))
            .finally(e => setloading(false))
    }

    return (
        <>
            <div style={{ margin: "0 auto", maxWidth: "500px", padding: "0 5%" }}>

                <Header style={{ background: "transparent", padding: 0 }}>
                    <ArrowLeftOutlined onClick={() => navigate(-1)} /> &ensp; <big>Signup</big>
                </Header> 

                <p>
                    If your account is not exist then account will be generated.
                </p> <br />

                Email : <br />
                <Input onChange={e => setdata({ ...data, email: e.target.value })} value={data.email} style={{ maxWidth: "300px" }} placeholder="eg. abc@gmail.com" /> <br /><br />

                Password : <br />
                <Input.Password onChange={e => setdata({ ...data, password: e.target.value })} value={data.password} style={{ maxWidth: "300px" }} placeholder="*********" /> <br /><br />

                <Button loading={loading} type='primary' onClick={handleSignup}>Signup</Button> <br /><br />

            </div>
        </>)
}

export default Signup
