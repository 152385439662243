import { EditFilled, UploadOutlined } from '@ant-design/icons'
import { Avatar, Button, Input, message, Spin, TimePicker } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import axios from '../axios/index'

const UpdateProfile = ({ user }) => {
    const [loading, setloading] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)

    useEffect(() => {
        setdata(user)
    }, [user])

    const [data, setdata] = useState({
        _id: "",
        email: "",
        password: "",
        logo: "",
        price: "",
        items: [],
        restra: "",
        opening: "",
        closing: "",
        google: "",
        facebook: "",
        insta: "",
        twitter: ""
    })

    const handleUpdate = () => {
        if (!data.restra) return message.warning("Restaurant name is required.")
        setloading(true)
        axios.put(`/user/${data._id}`, data)
            .then(e => {
                setdata(e?.data)
                message.success("Success.")
            })
            .catch(e => console.log(e))
            .finally(e => setloading(false))
    }

    return (
        <>

            <p> Email : <b>{data?.email}</b> </p>

            Restaurant Name : <br />
            <Input value={data?.restra} onChange={(e) => setdata({ ...data, restra: e.target.value })} style={{ maxWidth: "300px" }} placeholder="eg. xyz" /> <br /><br />

            Logo : (Optional) &ensp; <label htmlFor="logo"><EditFilled style={{ color: "red" }} /></label> &nbsp; {uploadLoading && <Spin size='small' spinning={true} />}<br /><br />


            {
                !data?.logo
                    ? <Avatar icon={<UploadOutlined />} shape='square' size={80} />
                    : <img src={data?.logo} alt="error" style={{ display: "block", maxHeight: "100px" }} />
            }

            <Input
                style={{ display: "none" }}
                accept='image/*'
                id='logo' type="file"
                onChange={async (e) => {
                    setUploadLoading(true);
                    var d = new FormData();
                    d.append("file", e.target.files[0])
                    var res = await axios.post("/upload", d);
                    setdata({ ...data, logo: res.data?.path })
                    setUploadLoading(false);
                }} />
            <br /><br />

            Open Time : (Optional)<br />
            <TimePicker
                use12Hours
                format="h:mm a"
                value={data?.opening ? dayjs(data?.opening, "h:mm a") : null}
                onChange={(_, e) => setdata({ ...data, opening: e })}
            /> <br /><br />

            Close Time : (Optional)<br />
            <TimePicker
                use12Hours
                format="h:mm a"
                value={data?.closing ? dayjs(data?.closing, "h:mm a") : null}
                onChange={(_, e) => setdata({ ...data, closing: e })}
            /> <br /><br />

            Google review url : (Optional)<br />
            <Input value={data?.google} onChange={(e) => setdata({ ...data, google: e.target.value })} placeholder='eg https://google.com' style={{ maxWidth: "300px" }} /> <br /><br />

            Facebook url : (Optional)<br />
            <Input value={data?.facebook} onChange={(e) => setdata({ ...data, facebook: e.target.value })} placeholder='eg https://facebook.com' style={{ maxWidth: "300px" }} /> <br /><br />

            Instagram url : (Optional)<br />
            <Input value={data?.insta} onChange={(e) => setdata({ ...data, insta: e.target.value })} placeholder='eg https://instagram.com' style={{ maxWidth: "300px" }} /> <br /><br />

            Twitter url : (Optional)<br />
            <Input value={data?.twitter} onChange={(e) => setdata({ ...data, twitter: e.target.value })} placeholder='eg https://twitter.com' style={{ maxWidth: "300px" }} /> <br /><br />

            <Button loading={loading} disabled={loading} type='primary' onClick={handleUpdate}> Update </Button> <br /><br />

        </>
    )
}

export default UpdateProfile