import { FacebookFilled, FilterOutlined, GoogleOutlined, HeartFilled, InstagramFilled, TwitterSquareFilled, WhatsAppOutlined } from '@ant-design/icons'
import { Avatar, Badge, Button, Card, Empty, Modal, Space } from 'antd'
import ButtonGroup from 'antd/es/button/button-group'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from '../axios'
import Loading from '../components/Loading'
import ReviewModal from '../components/ReviewModal'


const Rasta = ({ id }) => {

    const [user, setuser] = useState({})
    const [loading, setloading] = useState(false)
    const [filter, setfilter] = useState("ac")

    const param = useParams()

    useEffect(() => {
        setloading(true)
        axios.get(`/user/${param.id ?? id}`)
            .then(e => setuser(e?.data))
            .catch(e => console.log(e.error))
            .finally(e => setloading(false))
    }, [])
 
    return (
        <>
        {
            param.id && !user?.verify && !loading && (
               <ReviewModal />
            )
        }
            <div style={{ margin: "20px auto", maxWidth: "600px", filter: `grayscale(${(new Date(`${moment().format("ll")} ${user?.opening}`) > Date.now() || new Date(`${moment().format("ll")} ${user?.closing}`) < Date.now()) ? 1 : 0})` }}>

                {loading && <Loading />}
                {/* this is share and reveiw section  */}
                <ButtonGroup style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {user?.google && <Button href={user?.google} icon={<GoogleOutlined />}>Google Reveiw</Button>}
                    <Button href={`whatsapp://send?text=https://www.restraqr.in/${param.id ?? id}`} icon={<WhatsAppOutlined />}>Share Whatsapp</Button>
                </ButtonGroup>

                {/* this is rasto logo or name  */}
                {
                    !user?.logo
                        ? <div style={{
                            width: "220px",
                            color: "red",
                            fontSize: "30px",
                            textTransform: "uppercase",
                            textAlign: "center",
                            margin: "20px auto",
                            fontWeight: "bold",
                            wordBreak: "break-word"
                        }}>
                            {user?.restra}
                        </div>
                        : <img src={user?.logo} alt="error" style={{ display: "block", margin: "20px auto", maxHeight: "100px" }} />
                }


                {/* this is social media  account */}
                <div style={{ display: "flex", justifyContent: "center", gap: 10, flexWrap: "wrap" }}>
                    {user?.facebook && <Button href={user?.facebook} icon={<FacebookFilled />}>Facebook</Button>}
                    {user?.insta && <Button href={user?.insta} icon={<InstagramFilled />}>Instagram</Button>}
                    {user?.twitter && <Button href={user?.twitter} icon={<TwitterSquareFilled />}>Twitter</Button>}
                </div> <br />

                {/* this is category section  */}
               { user?.items?.length > 0 && <Space style={{ display: "flex", overflowX: "auto" }} className="scroll-bar-none">
                    <FilterOutlined />
                    <Button type={filter == "ac" ? "primary" : "default"} onClick={() => setfilter("ac")}>All Category</Button>
                    {
                        user.items?.length > 0 && [...new Set(user.items.map(e => e.category))].map(e => <Button onClick={() => setfilter(e)} type={filter == e ? "primary" : "default"}>{e}</Button>)
                    }
                </Space> } <br />



                {/* items section  */}

                <section style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    maxWidth: "400px",
                    margin: "0px auto 0px auto",
                    paddingLeft:"10px"
                }}>

                    {
                        user.items?.length > 0
                            ? user.items
                                .filter(e => filter == "ac" ? e : e.category == filter)
                                .map((e, i) => (
                                    <Badge.Ribbon key={i}
                                        placement="start"
                                        text={`₹ ${e.price}.00`}
                                        style={(e?.half || e?.full) ? { display: "none" } : { display: "block" }}
                                        color={e.inStock ? "blue" : "gray"}
                                    >
                                        <Badge.Ribbon
                                            placement="start"
                                            text={`Half ₹${e?.half}.00`}
                                            style={e?.half ? { display: "block" } : { display: "none" }}
                                            color={e.inStock ? "blue" : "gray"} >

                                            <Badge.Ribbon
                                                placement="start"
                                                text={`Full ₹${e?.full}.00`}
                                                style={e?.full ? { display: "block", marginTop: `${e?.half ? '25px' : '0px'}` } : { display: "none" }}
                                                color={e.inStock ? "blue" : "gray"}  >

                                                <Card
                                                    hoverable
                                                    size='small'
                                                    style={{
                                                        filter: `grayscale(${e.inStock ? 0 : 1})`,
                                                        width: "95%",
                                                        marginBottom: "10px"
                                                    }}
                                                    cover={<Avatar shape='square' style={{ width: "100%" }} size={120} src={e?.image} />}
                                                >
                                                    <div>
                                                        {e?.type && <HeartFilled style={e?.type == "Veg" ? { color: "green" } : { color: "red" }} />} &nbsp;
                                                        <b>{e?.title}</b>
                                                    </div>
                                                    {e?.desc && <span>{e?.desc}</span>}

                                                </Card>
                                            </Badge.Ribbon>
                                        </Badge.Ribbon>
                                    </Badge.Ribbon>
                                )) : <Empty style={{display:'block', margin:'auto'}} image={Empty.PRESENTED_IMAGE_DEFAULT} description="No Items" />
                    }
                </section>

                <p align="center">All right reserved &copy; <br /> {user?.restra} {new Date().getFullYear()}.</p> <br />

            </div>

            {/* for close or open resto  */}

            {(new Date(`${moment().format("ll")} ${user?.opening}`) > Date.now() || new Date(`${moment().format("ll")} ${user?.closing}`) < Date.now())
                && <div style={{
                    position: "fixed",
                    transform: "rotate(45deg)",
                    top: "-50px",
                    textAlign: "center",
                    right: "-50px",
                    width: "100px",
                    height: "100px",
                    color: "#ffffff",
                    background: "gray"
                }}>
                    <br /><br /><br /><br />closed
                </div>
            }

        </>
    )
}

export default Rasta
