import { Avatar, List } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from '../axios' 
import moment from 'moment'

const ShopList = () => { 
    const [data, setdata] = useState({})
    const [loading, setloading] = useState(false)

    useEffect(() => {
        setloading(true)
        axios.get(`/user`)
            .then(e => setdata(e?.data))
            .catch(e => console.log(e.error))
            .finally(e => setloading(false))
    }, [])


    return (
        <>
            <h3>Our Partners</h3>
            <List
                loading={loading}
                itemLayout="horizontal"
            >
                {data?.length > 0 && data?.filter(e => e?.verify).map(e =>
                    <List.Item key={e._id}
                    style={{
                        filter: `grayscale(${(new Date(`${moment().format("ll")} ${e?.opening}`) > Date.now() || new Date(`${moment().format("ll")} ${e?.closing}`) < Date.now()) ? 1 : 0})`
                    }}
                        actions={[new Date(`${moment().format("ll")} ${e?.opening}`) > Date.now() || new Date(`${moment().format("ll")} ${e?.closing}`) < Date.now() ? <font color="red">Closed {e?.closing}</font> : <font color="green">Open</font> ]}>
                        <List.Item.Meta
                            avatar={<Avatar size={50} shape='square' src={e?.logo ?? `image/logo.png`} />}
                            title={<a href={`https://www.restraqr.in/${e._id}`}>{e?.restra ?? "Unknown"}</a>}
                            description={e?.items.length + " Dishes "}
                        />
                    </List.Item>)}
            </List>
        </>
    )
}

export default ShopList