import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Avatar, Button, Drawer, Input, message, Segmented, Spin, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from '../axios'

const UpdateItem = ({ item, user, onClose }) => {
    const [userData, setUserData] = useState(user)
    const [loading, setloading] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)

    useEffect(() => {
        setdata(user.items.filter(e => e.title == item)[0])
    }, [user, item])

    const [data, setdata] = useState({
        image: "",
        id: "",
        title: "",
        desc: "",
        price: "",
        half: "",
        full: "",
        category: "",
        inStock: 1,
        type: ""
    })
    const handleUpdate = () => {
        if (!data.title) return message.warning("Title is required.")
        if (!data.price) return message.warning("Price is required.")
        if (!data.category) return message.warning("Category is required.")
        setloading(true)
        setUserData({ ...userData }, userData?.items.map(e => {
            if (e.id == data.id) {
                e.price = data.price
                e.half = data.half
                e.full = data.full
                e.inStock = data.inStock
                e.type = data.type
                e.title = data.title
                e.category = data.category
                e.image = data.image
                e.desc = data.desc
            }
        }))
        axios.put(`/user/${user._id}`, userData)
            .then(e => {
                onClose()
                message.success("Success.")
            })
            .catch(e => console.log(e))
            .finally(e => setloading(false))

    }

    return (
        <>
            <Drawer open onClose={onClose} placement='bottom' height="90%" title="Update Item">


                <Segmented
                    options={["Veg", 'Nonveg']}
                    style={{ background: "lightgray" }}
                    value={data?.type}
                    onChange={(e) => setdata({ ...data, type: e })}
                />&ensp;


                Availablity : <Switch checked={data?.inStock} size='small' onChange={(e) => setdata({ ...data, inStock: e })} /> <br /><br />


                Image :
                <Button type='link'><label htmlFor="image"><EditOutlined /></label></Button>
                {uploadLoading && <Spin size='small' spinning={true} />}<br /><br />

                <Avatar src={data?.image} shape='square' size={80} />
                <Input
                    style={{ display: "none" }}
                    accept='image/*'
                    id='image' type="file"
                    onChange={async (e) => {
                        setUploadLoading(true);
                        var d = new FormData();
                        d.append("file", e.target.files[0])
                        var res = await axios.post("/upload", d);
                        setdata({ ...data, image: res.data?.path })
                        setUploadLoading(false);
                    }} />
                <br /><br />

                Title : <br />
                <Input value={data?.title} onChange={(e) => setdata({ ...data, title: e.target.value })} placeholder='eg. Roti' style={{ width: "200px" }} />
                <br /><br />

                Description : <br />
                <Input.TextArea value={data?.desc} onChange={(e) => setdata({ ...data, desc: e.target.value })} placeholder='eg Dal Makhani' style={{ maxWidth: "300px" }} /> <br /><br />
 
                <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                    <div>
                        Price : <br />
                        <Input value={data?.price} onChange={(e) => setdata({ ...data, price: e.target.value })} prefix="₹" placeholder='eg. 300' suffix=".00" style={{ width: "120px" }} type="number" />
                    </div>

                    <div>
                        Half Price : (Optional) <br />
                        <Input value={data?.half} onChange={(e) => setdata({ ...data, half: e.target.value })} prefix="₹" placeholder='eg. 300' suffix=".00" style={{ width: "120px" }} type="number" />
                    </div>

                    <div>
                        Full Price : (Optional) <br />
                        <Input value={data?.full} onChange={(e) => setdata({ ...data, full: e.target.value })} prefix="₹" placeholder='eg. 300' suffix=".00" style={{ width: "120px" }} type="number" />
                    </div>
                </div><br />

                Category : <br />
                <Input list='catelist' value={data?.category} onChange={(e) => setdata({ ...data, category: e.target.value })} placeholder='eg. Dal' style={{ maxWidth: "200px" }} /> <br /><br />
                <br />

                <datalist id='catelist'>
                    {
                        user?.items?.length > 0 && [...new Set(user?.items?.map(e => e.category))].map(e => <option value={e} />)
                    }  
                </datalist>


                <Button disabled={loading} onClick={handleUpdate} loading={loading} type='primary'>Update</Button>
            </Drawer>
        </>
    )
}

export default UpdateItem