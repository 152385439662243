import { ArrowLeftOutlined, LinkOutlined } from '@ant-design/icons'
import { Header } from 'antd/es/layout/layout'
import Loading from '../components/Loading'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UpdateProfile from '../components/UpdateProfile'
import ItemTable from '../components/ItemTable'
import axios from '../axios/index'
import Rasta from '../pages/Restra'
import { Button, Popover, Segmented } from 'antd'

const Profile = () => {
    const navigate = useNavigate()
    const [user, setuser] = useState({})
    const [loading, setloading] = useState(false)
    const [component, setComponent] = useState("Profile")

    useEffect(() => {
        setloading(true)
        axios.get(`/user/${localStorage.getItem('token')}`)
            .then(e => setuser(e?.data))
            .catch(e => console.log(e.error))
            .finally(e => setloading(false))
    }, [])

    return (
        <>
            {loading && <Loading />}
            <div style={{ margin: "0 auto", maxWidth: "500px", padding: "0 5%" }}>

                <Header style={{ background: "transparent", padding: 0 }}>
                    <ArrowLeftOutlined onClick={() => navigate(-1)} /> &ensp; <big> Dashboard </big> <Button type='link' onClick={() => navigate(`/${user?._id}`)} size='large'><LinkOutlined /></Button>
                </Header>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Segmented
                        onChange={setComponent}
                        options={["Profile", "Items","Reveiw"]}
                        default="Profile"
                        style={{ background: "lightgray" }}
                    />
                    <Popover
                        placement="leftTop"
                        content={ 
                            <Button danger onClick={() => localStorage.removeItem("token") || (window.location.href = "/")}>Logout</Button>
                        }
                        title={<span>Logout Account<br /> <i>Are you sure want to Logout?</i></span>}
                        trigger="click">
                        <Button type='primary' danger>Logout</Button>
                    </Popover>
                </div>

                {component === "Profile" && <UpdateProfile user={user} />}
                {component === "Items" && <ItemTable user={user} />}
                {component === "Reveiw" && <Rasta id={user?._id} />}


                {/*  */}

            </div>
        </>)
}

export default Profile



