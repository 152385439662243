import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { Avatar, Button, Input, message, Popover, Table } from 'antd';
import ButtonGroup from 'antd/es/button/button-group'
import AddItem from "./AddItem";
import UpdateItem from './UpdateItem';
import Loading from './Loading';
import axios from '../axios';

const ItemTable = ({ user }) => {
    const [updateItem, setUpdateItem] = useState()
    const [loading, setloading] = useState(false)
    const [userData, setUserData] = useState(user)
    const [additem, setAdditem] = useState(false)
    const [inputFilter, setInputFilter] = useState("")
    useEffect(() => {
        setUserData(user)
    }, [user])

    const columns = [
        {
            title: 'Image',
            key: 'image',
            render: (_, record) => (
                <Avatar shape='square' size={40} src={record.image} />
            ),
        },
        {
            title: 'Title',
            key: 'title',
            render: (_, record) => record.inStock ? record.title : <del>{record.title}</del>
        }, 
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <ButtonGroup size='small'>
                    <Button type='primary' ghost onClick={() => setUpdateItem(record.title)}><EditOutlined /></Button>
                    <Popover
                        placement="leftTop"
                        content={
                            <Button onClick={() => handleDelete(record)} size='small' danger>Delete</Button>
                        }
                        title={<span>Item Delete<br /> <i>Are you sure want to delete?</i></span>}
                        trigger="click">
                        <Button ghost danger><DeleteOutlined /></Button>
                    </Popover>
                </ButtonGroup>
            ),
        },
    ];
    const handleDelete = (data) => {
        setloading(true)
        axios.patch(`/user/deleteItem/${user?._id}`, { ...data })
            .then(e => {
                window.location.reload()
            })
            .catch(e => console.log(e))
            .finally(e => setloading(false))
    }

    return (
        <>
            {/* filter part  */}
            <br /> <br />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Input placeholder='Search item title' onChange={(e) => setInputFilter(e.target.value)} style={{ width: "200px" }} />
                <Button type='primary' onClick={() => setAdditem(true)}>Create New</Button>
            </div><br />

            {loading && <Loading />}

            {updateItem && <UpdateItem item={updateItem} user={user} onClose={() => setUpdateItem()} />}
            {additem && <AddItem user={user} onClose={() => setAdditem(false)} />}

            <Table columns={columns} dataSource={user?.items?.filter(e => e.title.toLowerCase().indexOf(inputFilter.toLowerCase()) > -1)} />

        </>)
}

export default ItemTable



